@if (textarea()) {
	<!--
		Use `ion-textarea` component for textarea input on the mobiles devices.
		Done on purpose to avoid weird behavior
		of cursor caret for iOS devices, when keyboard is appeared.
		See: https://github.com/ionic-team/ionic-plugin-keyboard/issues/228
	-->
	@if (isMobilePlatform) {
		<ion-textarea
			class="wndr-textarea"
			[class.wndr-textarea_full-height]="fullHeightTextarea()"
			[placeholder]="placeholder()"
			[autoGrow]="!fullHeightTextarea()"
			[(ngModel)]="controlValue"
			[disabled]="disabled"
			(focus)="focused.emit()"
			(blur)="emitTouched(); blurred.emit()"
		>
			<ng-container *ngTemplateOutlet="suffix" />
		</ion-textarea>
	} @else {
		<textarea
			#input
			class="wndr-textarea"
			[class.wndr-input_iconed]="iconed()"
			[class.wndr-textarea_clearable]="clearInput()"
			[class.wndr-textarea_full-height]="fullHeightTextarea()"
			[placeholder]="placeholder()"
			[autocomplete]="autocomplete()"
			[(ngModel)]="controlValue"
			(focus)="focused.emit()"
			(blur)="emitTouched(); blurred.emit()"
			[cdkTextareaAutosize]="!fullHeightTextarea()"
			[cdkAutosizeMinRows]="autoResizeTextareaConfig()?.minRows"
			[cdkAutosizeMaxRows]="autoResizeTextareaConfig()?.maxRows"
			[disabled]="disabled"
		></textarea>
	}
} @else {
	<input
		#input
		[type]="type()"
		class="wndr-input"
		[class.wndr-input_iconed]="iconed()"
		[class.wndr-input_transparent]="hasOpacity()"
		[placeholder]="placeholder()"
		[autocomplete]="autocomplete()"
		[unmask]="unmask()"
		[ngModel]="controlValue"
		(ngModelChange)="onValueChange($event)"
		[imask]="mask()"
		(focus)="focused.emit()"
		(blur)="emitTouched(); blurred.emit()"
		[disabled]="disabled"
	/>
}

@if (!textarea() && !isMobilePlatform) {
	<div
		class="icon-wrapper"
		[ngClass]="{ 'icon-wrapper_for-textarea': textarea(), 'visually-hidden': !iconed() }"
	>
		<ng-container *ngTemplateOutlet="suffix" />
	</div>
}

<!--
	Done on purpose. We can't duplicate `<ng-content/>` for different conditions.
	So wrapping this one inside `ng-template` fixes it.
	See: https://github.com/angular/angular/issues/7795#issuecomment-361552386
-->
<ng-template #projected>
	<ng-content />
</ng-template>

<ng-template #suffix>
	@if (clearInput() && controlValue && !disabled) {
		<button
			slot="end"
			type="button"
			wndrcIconButton
			size="small"
			fill="clear"
			(click)="onClearButtonClick()"
		>
			<ion-icon name="close-outline" />
		</button>
	} @else {
		<ng-container *ngTemplateOutlet="projected" />
	}
</ng-template>
