import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	NgZone,
	Output,
	booleanAttribute,
	computed,
	contentChild,
	effect,
	inject,
	input,
	viewChild,
} from '@angular/core';
import { controlProviderFor } from '@wndr/common/core/utils/value-accessor';
import { FormsModule } from '@angular/forms';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';
import { IonIcon, IonTextarea } from '@ionic/angular/standalone';
import { IMaskDirective, IMaskModule } from 'angular-imask';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

import { take } from 'rxjs';
import { PlatformService } from '@wndr/common/core/services/platform.service';

import { InputBase } from '../input-base';
import { IconButtonDirective } from '../../buttons/icon-button.directive';

import { InputSuffixDirective } from './input-suffix.directive';

/** Input component. */
@Component({
	selector: 'wndrc-input',
	standalone: true,
	imports: [NgTemplateOutlet, IonTextarea, IonIcon, FormsModule, IconButtonDirective, IMaskModule, NgClass, CdkTextareaAutosize],
	templateUrl: './input.component.html',
	styleUrls: ['../input.css', './input.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [controlProviderFor(() => InputComponent)],
})
export class InputComponent extends InputBase {

	/** Whether input has clear button by default. */
	public readonly clearInput = input(false, { transform: booleanAttribute });

	/** Whether should use textarea instead. */
	public readonly textarea = input(false, { transform: booleanAttribute });

	/** Whether should apply autofocus to input element. */
	public readonly autofocus = input(false, { transform: booleanAttribute });

	/** Whether the textarea should not have row restrictions and instead take 100% of height. */
	public readonly fullHeightTextarea = input(false, { transform: booleanAttribute });

	/** On input focus. */
	@Output()
	public readonly focused = new EventEmitter<void>();

	/** On input blur. */
	@Output()
	public readonly blurred = new EventEmitter<void>();

	/** Config for auto resizable text area. */
	protected readonly autoResizeTextareaConfig = computed(() => {
		if (this.fullHeightTextarea()) {
			return undefined;
		}
		return {
			maxRows: 12,
			minRows: 5,
		};
	});

	/** Returns the content type of the object. */
	public readonly type = input<HTMLInputElement['type']>('text');

	/** Mask. */
	public readonly mask = input<IMaskDirective<{}, true | 'typed', string>['imask']>(undefined);

	// Add unmask to use number value (https://github.com/uNmAnNeR/imaskjs/issues/304)
	/** Unmask. */
	public readonly unmask = input<boolean | 'typed'>(true);

	/** Input suffix component. */
	private readonly inputSuffixComponent = contentChild(InputSuffixDirective);

	/** Has child. */
	private readonly hasChild = computed(() => this.inputSuffixComponent() !== undefined);

	/** Whether input has icon or not. */
	protected readonly iconed = computed(() => this.hasChild() || this.clearInput());

	/** Instance of cdk textarea autosize. */
	protected readonly autosize = viewChild(CdkTextareaAutosize);

	/** Whether app is running on mobile platform or not. */
	protected readonly isMobilePlatform = inject(PlatformService).isMobile();

	private readonly inputRef = viewChild<ElementRef<HTMLInputElement> | ElementRef<HTMLTextAreaElement>>('input');

	public constructor(private readonly ngZone: NgZone) {
		super();
		addIcons({ closeOutline });

		effect(() => {
			if (this.autofocus()) {
				this.inputRef()?.nativeElement.focus();
			}
		});
	}

	/**
	 * Handle value change of the input.
	 * @param value New value.
	 */
	protected onValueChange(value: string): void {
		if (this.type() === 'number') {
			const parsedNumber = Number.parseFloat(value);
			if (Number.isNaN(parsedNumber)) {
				this.controlValue = null;
			} else {
				this.controlValue = parsedNumber as unknown as string;
			}
		} else {
			this.controlValue = value;
		}
	}

	/** On clear button click. */
	protected onClearButtonClick(): void {
		this.controlValue = '';
	}

	/** @inheritdoc */
	public override writeValue(value: string | null): void {
		super.writeValue(value);

		if (this.textarea()) {
			// Hack to render textarea correctly.
			setTimeout(() => {
				this.ngZone.onStable.pipe(
					take(1),
				).subscribe(() => this.autosize()?.resizeToFitContent(true));
			}, 50);
		}
	}

	/** Full height class. */
	@HostBinding('class.full-height')
	public get fullHeightClass(): boolean {
		return this.fullHeightTextarea();
	}
}
